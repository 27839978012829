<template>
  <div>
    <div class="flex justify-center">
      <span class="font-bold text-2xl">Listado de informes</span>
    </div>
    <div class="flex justify-end p-5">
      <Button
        icon="pi pi-plus"
        class="p-button-rounded"
        v-if="$can('pharmasan.dashboard.informe-ejecutivo.access')"
        v-tooltip.top="'Nuevo Informe'"
        @click="openCreate"
      />
    </div>
    <div>
      <informesList/>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { useRouter } from 'vue-router'

export default {
  name: 'index',
  components: {
    informesList: defineAsyncComponent(() => import('./components/tableList'))
  },
  setup () {
    const router = useRouter()
    const openCreate = () => {
      router.push({ name: 'dashboard.informe.ejecucion.create' })
    }
    return {
      openCreate
    }
  }
}
</script>

<style scoped>

</style>
